window.initializeDynamicSelect = function (mainSelectId, dependentSelectId, fetchPath, filterKey, selectedValue = null) {
  var dependentElement = document.getElementById(dependentSelectId);
  var dependentSlimSelect = new SlimSelect({
    select: `#${dependentSelectId}`,
    settings: {
      searchPlaceholder: dependentElement.getAttribute('include_blank'),
    }
  });

  var allOptions = dependentSlimSelect.getData();

  function updateSelectOptions(newOptions) {
    const optionsWithBlank = [
      { text: dependentElement.getAttribute('include_blank'), value: '', selected: !selectedValue },
      ...newOptions
    ];
    dependentSlimSelect.setData([]);
    dependentSlimSelect.setData(optionsWithBlank.map(option => ({
      text: option.text,
      value: option.value,
      selected: option.value == selectedValue
    })));
  }


  document.getElementById(mainSelectId).addEventListener('change', function () {
    const mainSelectValue = this.value;
    if (mainSelectValue) {
      fetch(`${fetchPath}?${filterKey}=${mainSelectValue}`)
        .then(response => response.json())
        .then(data => {
          const newOptions = data.map(item => ({
            text: item[1],
            value: item[0]
          }));
          updateSelectOptions(newOptions);
        })
        .catch(error => console.error('Error:', error));
    } else {
      updateSelectOptions(allOptions);
    }
  });

  updateSelectOptions(allOptions);
};
